// @flow
import type { TreeNode } from "containers/TreeNodeView/types";
import type { StaffDto } from "./routes/private/Site/types";

// This file is ordered alphabetically by API path (e.g. /contest/search),
// not by React key (e.g. CONTEST_SEARCH). It is also divided by module for each
// group of calls.
// Make sure the API path is always on a new line so that the paths line up.
//
// For example:
//    Bad:
//        COUNTRY: `${Modules.COMMON}/country/search`,
//
//    Good:
//        COUNTRY:
//          `${Modules.COMMON}/country/search`,

export const Modules = {
  PERSON: "/person",
  TEAM: "/team",
  CONTEST: "/contest",
  COMMON: "/common",
  HELP: "/help",
  ASPECT_FACES: "/aspectfaces",
  HOTEL: "/hotel/private/dashboard",
  CONTEST_FINDER: "/regionals/finder",
  HOTEL_COMMON: "/icpchotel",
  PROFILE_COMMON: "/icpcprofile",
};

// TODO remove this later
export const Link = {
  CONTEST: (id: number) => `/private${Modules.CONTEST}/${id}`,
  HOTEL: (id: number) => `${Modules.HOTEL}?contestId=${id}`,
  SITE: (id: number) => `/site/${id}`,
};

export const TableResources = {
  // COMMON
  COUNTRY: `${Modules.COMMON}/country/search`,
  INSTITUTION: `${Modules.COMMON}/institution/search`,
  INSTITUTION_UNIT_HISTORY: (unitId: number) =>
    `${Modules.COMMON}/institutionunit/${unitId}/history`,
  SUGGESTED_INSTITUTION: `${Modules.COMMON}/suggestedinstitution`,

  // CONTEST
  TRANSPORTATION_ENDPOINTS: (id: number) =>
    `${Modules.CONTEST}/transportation/endpoints/${id}`,
  TRANSPORTATION_COMPANIES: (id: number) =>
    `${Modules.CONTEST}/transportation/companies/${id}`,
  CONTEST_ACCESS_PERMISSIONS: (contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/managers`,

  TEAM_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/search`,
  STAFF_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/search`,
  TEAM_CERTIFICATE: (contestId: number) =>
    `${Modules.CONTEST}/certificate/team/contest/${contestId}/search`,
  STAFF_CERTIFICATE: (contestId: number) =>
    `${Modules.CONTEST}/certificate/staff/contest/${contestId}/search`,
  MANUAL_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/search/manual`,
  CONTEST_STRUCTURE_PRELIMINARY_CONTESTS: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/preliminarycontest/search`,
  CONTEST_STRUCTURE_SITES: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/site/search`,
  CONTEST_STRUCTURE_SUBCONTESTS: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/subcontest/search`,
  PEOPLE_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/contestparticipant`,
  INSTITUTION_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/institution`,
  STAFF_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/staff`,
  TEAM_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/team`,
  TEAM_MEMBERS_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/teammember`,
  TOP20_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/top20`,
  SITE_STAFF: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/staff`,
  SITE_TEAMS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/teams`,
  SITE_PEOPLE: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/people`,
  SITE_TEAM_MEMBERS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/teammembers`,
  SITE_SEARCHES_INSTITUTIONS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/institutions`,
  SITE_SEARCHES_TEAMS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/teams`,
  SITE_TSHIRT_ATTENDEE: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/attendees`,
  SITE_TSHIRT_COACH: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/coaches`,
  SITE_TSHIRT_COCOACH: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/cocoaches`,
  SITE_TSHIRT_CONTESTANT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/contestants`,
  SITE_TSHIRT_RESERVE: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/reserves`,
  SITE_TSHIRT_STAFF: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/staff`,
  RULES_HISTORY: (contestId: number) =>
    `${Modules.CONTEST}/contest/rulesHistory/${contestId}`,
  STANDINGS: (contestId: number) =>
    `${Modules.CONTEST}/standings/contest/${contestId}/search`,
  TRANSPORTATION_MISSING_RESPONSE: (contestId: number) =>
    `${Modules.CONTEST}/transportation/search/contest/${contestId}/missing`,
  TRANSPORTATION_RESPONSE: (contestId: number) =>
    `${Modules.CONTEST}/transportation/search/contest/${contestId}/response`,
  TRANSPORTATION_RESPONSE_BY_PERSONS: (contestId: number, persons: string) =>
    `${Modules.CONTEST}/transportation/search/contest/${contestId}/response/${
      persons.length == 0 ? "-1" : persons
    }`,
  TEAM_TSHIRT_REPORT: (teamRole: string, contestId: number) =>
    `${Modules.CONTEST}/report/tshirt/${teamRole}/${contestId}`,
  STAFF_FUNDING: (contestId: number) =>
    `${Modules.CONTEST}/staffMember/funding/contest/${contestId}/search`,

  // PERSON
  PERSON_REFERENCES_CONTEST_MANAGER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/contestmanager/search`,
  PERSON_REFERENCES_MASTER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/master/search`,
  PERSON_REFERENCES_SITE_MANAGER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/sitemanager/search`,
  PERSON_REFERENCES_SLAVE: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/slave/search`,
  PERSON_REFERENCES_SPONSOR: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/sponsor/search`,
  PERSON_REFERENCES_STAFF_MEMBER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/staffmember/search`,
  PERSON_REFERENCES_TEAM_MEMBER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/teammember/search`,
  PERSON: `${Modules.PERSON}/search/all`,

  // TEAM
  TEAM: `${Modules.TEAM}/search/all`,
  TEAM_MY_BY_YEAR: `${Modules.TEAM}/search/my`,
  TEAM_BY_SITE: `${Modules.TEAM}/search/site`,
  TEAMS_FOR_CONTEST: (contestId: number) =>
    `${Modules.TEAM}/search/${contestId}/all`,
  PROMOTION_TEAMS_FOR_CONTEST: (contestId: number) =>
    `${Modules.TEAM}/search/${contestId}/promote`,
  PENDING_TEAMS_FOR_CONTEST: (contestId: number) =>
    `${Modules.TEAM}/search/${contestId}/pending`,
};

export const TreeNodeGET = {
  // CONTEST
  GENERAL_DESCENDANT_BY_CONTEST_ID: (contestId: number) =>
    `${Modules.CONTEST}/site/tree/${contestId}`,
  GENERAL_DESCENDANT: (contest: TreeNode) =>
    TreeNodeGET.GENERAL_DESCENDANT_BY_CONTEST_ID(contest.id),
  BY_ROLE: (contestId: number) =>
    `${Modules.CONTEST}/site/tree/${contestId}/byrole`,
  BY_ROLE_EAGER: (contestId: number) =>
    `${Modules.CONTEST}/site/tree/${contestId}/byrole/eager`,
  BY_ROLE_MAX: (contestId: number) =>
    `${Modules.CONTEST}/site/tree/${contestId}/byrolemax`,
  BY_ROLE_MAX_EAGER: (contestId: number) =>
    `${Modules.CONTEST}/site/tree/${contestId}/byrolemax/eager`,
  FOR_REGISTRATION_BY_ABBR: (abbr: string) =>
    `${Modules.CONTEST}/site/tree/registration/abbr/${abbr}`,
  FOR_REGISTRATION_BY_ABBR_EAGER: (abbr: string) =>
    `${Modules.CONTEST}/site/tree/registration/abbr/${abbr}/eager`,
  FOR_REGISTRATION_BY_CONTEST_ID: (contest: TreeNode) =>
    `${Modules.CONTEST}/site/tree/registration/id/${contest.id}`,
  GENERAL_ROOT: () => `${Modules.CONTEST}/site/tree/root`,
  GENERAL_ROOT_EAGER: () => `${Modules.CONTEST}/site/tree/root/eager`,
  GENERAL_ROOT_FOR_YEAR: (year: number) =>
    `${Modules.CONTEST}/site/tree/root/year/${year}`,
  GENERAL_ROOT_EAGER_FOR_YEAR: (year: number) =>
    `${Modules.CONTEST}/site/tree/root/year/${year}/eager`,
  DASHBOARD_CONTESTS_FOR_YEAR: (year: number) =>
    `${Modules.CONTEST}/tree/year/${year}`,
};

export const Export = {
  // COMMON
  COUNTRY: `${Modules.COMMON}/country/export`,
  INSTITUTION: `${Modules.COMMON}/institution/export`,

  // CONTEST
  EVENT_RESPONSES_EXPORT: (eventId: number) =>
    `${Modules.COMMON}/event/responses/${eventId}/table/export`,
  SURVEY_RESPONSES_TABLE_EXPORT: (surveyId: number) =>
    `${Modules.CONTEST}/survey/responses/${surveyId}/table/export`,
  RELAXED_REGIONAL: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/regional/${contestId}`,
  RELAXED_TEAM: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/team/${contestId}`,
  RELAXED_COACH: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/coach/${contestId}`,
  RELAXED_UNIVERSITY: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/university/${contestId}`,
  RELAXED_COUNTRY: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/country/${contestId}`,
  RELAXED_SITE: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/site/${contestId}`,
  RELAXED_ALL: (contestId: number) =>
    `${Modules.CONTEST}/export/relaxReport/all/${contestId}`,
  ALL_MANUAL_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/report`,
  FILTERED_MANUAL_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/report/filtered`,
  SELECTED_MANUAL_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/report/selected`,
  ALL_STAFF_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/report`,
  FILTERED_STAFF_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/report/filtered`,
  SELECTED_STAFF_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/report/selected`,
  ALL_TEAM_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/report`,
  FILTERED_TEAM_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/report/filtered`,
  SELECTED_TEAM_BADGES: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/report/selected`,
  ALL_STAFF_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/staff/contest/${contestId}/report`,
  FILTERED_STAFF_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/staff/contest/${contestId}/report/filtered`,
  SELECTED_STAFF_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/staff/contest/${contestId}/report/selected`,
  ALL_TEAM_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/team/contest/${contestId}/report`,
  FILTERED_TEAM_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/team/contest/${contestId}/report/filtered`,
  SELECTED_TEAM_CERTIFICATES: (contestId: number) =>
    `${Modules.CONTEST}/certificate/team/contest/${contestId}/report/selected`,
  MY_CERTIFICATE: (certificateDetailsId: number) =>
    `${Modules.CONTEST}/certificate/download/${certificateDetailsId}`,
  CERTIFICATE: (
    certificateOption: string,
    id: number,
    certificateType: string
  ) =>
    `${Modules.CONTEST}/certificate/download/${certificateOption}/${id}/${certificateType}`,
  SITE_PC2: (siteId: number) => `${Modules.CONTEST}/export/site/${siteId}/pc2`,
  SITE_PRINT_DATA: (siteId: number) =>
    `${Modules.CONTEST}/export/site/${siteId}/printdata`,
  EXPORT_ATTACHMENTS: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/attachments/contest/${contestId}/${type}/download`,
  EXPORT_CDP: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cdp/contest/${contestId}/${type}/download`,
  EXPORT_CLICS: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/clics/contest/${contestId}/${type}/download`,
  EXPORT_CPF: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cpf/contest/${contestId}/${type}/download`,
  EXPORT_CONTEST_DATA: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/contest/${contestId}/${type}/download`,
  EXPORT_CONTEST_EMAIL: (contestId: number) =>
    `${Modules.CONTEST}/export/email/contest/${contestId}/download`,
  EXPORT_PASSPORT_INFO: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/passportInfo/contest/${contestId}/${type}/download`,
  EXPORT_HOST_INVITATION: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/hostinvitation/contest/${contestId}/${type}/download`,
  EXPORT_PC2: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pc2/contest/${contestId}/${type}/download`,
  EXPORT_ALL_RELAXED: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/relaxReport/all/contest/${contestId}/${type}/download`,
  EXPORT_PLANNING_NEXT_YEAR: (contestId: number) =>
    `${Modules.CONTEST}/export/planning/contest/${contestId}/download`,
  EXPORT_TEAM_PDF: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pdf/contest/${contestId}/${type}/download`,
  EXPORT_WF: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/wf/contest/${contestId}/${type}/download`,
  PEOPLE_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/contestparticipant/export`,
  INSTITUTION_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/institution/export`,
  STAFF_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/staff/export`,
  TEAM_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/team/export`,
  TEAM_MEMBERS_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/teammember/export`,
  TOP20_SEARCH: (contestId: number) =>
    `${Modules.CONTEST}/search/contest/${contestId}/top20/export`,
  TRANSPORTATION_RESPONSE: (contestId: number) =>
    `${Modules.CONTEST}/transportation/search/contest/${contestId}/response/export`,
  SITE_TEAMS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/teams/export`,
  SITE_PEOPLE: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/people/export`,
  SITE_TEAM_MEMBERS: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/home/teammembers/export`,
  SITE_TSHIRT_ATTENDEE_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/attendees/export`,
  SITE_TSHIRT_COACH_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/coaches/export`,
  SITE_TSHIRT_COCOACH_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/cocoaches/export`,
  SITE_TSHIRT_CONTESTANT_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/contestants/export`,
  SITE_TSHIRT_RESERVE_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/reserves/export`,
  SITE_TSHIRT_STAFF_EXPORT: (siteId: number) =>
    `${Modules.CONTEST}/search/site/${siteId}/tshirt/staff/export`,
  SITE_EMAILS: (siteId: number) => `${Modules.CONTEST}/site/${siteId}/emails`,
  CONTEST_STAFF_MEMBERS_EXPORT: (contestId: number) =>
    `${Modules.CONTEST}/staffmember/contest/${contestId}/search/export`,
  CONTEST_LABELS_EXPORT: (contestId: number) =>
    `${Modules.CONTEST}/labels/contest/${contestId}/export`,
  CONTEST_TSHIRTS_EXPORT: (contestId: number) =>
    `${Modules.CONTEST}/staffmember/tshirt/contest/${contestId}/search/export`,
  STANDINGS: (standingsId: number) =>
    `${Modules.CONTEST}/standings/${standingsId}/export`,
  STANDINGS_TEMPLATE: (contestId: number) =>
    `${Modules.CONTEST}/standings/contest/${contestId}/template`,
  TRANSPORTATION_MISSING_RESPONSE: (contestId: number) =>
    `${Modules.CONTEST}/transportation/search/contest/${contestId}/missing/export`,
  TEAM_TSHIRT_REPORT: (contestId: number) =>
    `${Modules.CONTEST}/report/${contestId}/tshirt/member/export`,
  STAFF_TSHIRT_REPORT: (contestId: number) =>
    `${Modules.CONTEST}/report/${contestId}/tshirt/staff/export`,
  STAFF_FUNDING: (contestId: number) =>
    `${Modules.CONTEST}/staffMember/funding/contest/${contestId}/search/export`,
  // HOTEL
  PERSON_LETTER_STAFF: (personId: number, contestId: number) =>
    `${Modules.HOTEL_COMMON}/person/${personId}/staffInvitationletter/${contestId}`,

  // PERSON
  PERSON_APPOINTMENT_LETTERS: () =>
    `${Modules.PERSON}/files/appointmentletters`,
  PERSON_APPOINTMENT_LETTER: (personId: number, alId: number) =>
    `${Modules.PERSON}/pdf/export/${personId}/appointmentletter/contest/${alId}`,
  PERSON_LETTER_FUNDING: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/export/pdf/person/${personId}/fundingletter/contest/${contestId}`,
  PERSON_HOST_INVITATION: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/export/pdf/person/${personId}/hostinvitationletter/contest/${contestId}`,
  PERSON_ANNOUNCEMENT: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/export/pdf/person/${personId}/announcement/contest/${contestId}`,
  PERSON_ANNOUNCEMENT2: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/export/pdf/person/${personId}/announcement2/contest/${contestId}`,
  PERSON_LETTER_ROOMRESERVATION: (personId: number, contestId: number) =>
    `${Modules.PERSON}/pdf/export/${personId}/roomReservation/${contestId}`,
  TEAM_LETTER: (personId: number, contestId: number) =>
    `${Modules.PERSON}/pdf/export/${personId}/teaminvitationletter/contest/${contestId}`,
  PERSONAL_FILES: (personId: number, fileId: number) =>
    `${Modules.PERSON}/personalfile/${personId}/download/${fileId}`,
  MY_PERSONAL_FILES: (fileId: number) =>
    `${Modules.PERSON}/personalfile/download/${fileId}`,
  PERSON_REFERENCES_CONTEST_MANAGER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/contestmanager/export`,
  PERSON_REFERENCES_MASTER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/master/export`,
  PERSON_REFERENCES_SITE_MANAGER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/sitemanager/export`,
  PERSON_REFERENCES_SLAVE: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/slave/export`,
  PERSON_REFERENCES_SPONSOR: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/sponsor/export`,
  PERSON_REFERENCES_STAFF_MEMBER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/staffmember/export`,
  PERSON_REFERENCES_TEAM_MEMBER: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/${year}/teammember/export`,
  PERSON: `${Modules.PERSON}/search/all/export`,
  PERSON_VISA_INVITATION: (personId: number) =>
    `${Modules.PERSON}/visainvitation/download/${personId}`,

  // TEAM
  TEAM_FILE_DOWNLOAD: (teamFileId: number) =>
    `${Modules.TEAM}/file/download/${teamFileId}`,
  TEAM_MEMBER_EMAILS: (teamId: number) =>
    `${Modules.TEAM}/members/export/${teamId}`,
  TEAM: `${Modules.TEAM}/search/all/export`,
  TEAMS_FOR_CONTEST: (contestId: number) =>
    `${Modules.TEAM}/search/${contestId}/all/export/`,

  // TODO REMOVE THIS
  // TO REMOVE (MIGRATED TO HOTEL)
  LABEL_GROUP_CSV: (groupId: number) =>
    `${Modules.CONTEST}/label/export/${groupId}`,
  ALL_LABEL_GROUP_CSV: (userId: number) =>
    `${Modules.CONTEST}/label/exportall/${userId}`,
};

export const DataGET = {
  // COMMON
  COUNTRY: `${Modules.COMMON}/country`,
  EVENT: (id: number) => `${Modules.COMMON}/event/${id}`,
  GET_ALL_EVENTS: (contestId: number) =>
    `${Modules.COMMON}/event/contest/${contestId}`,
  EVENT_FOR_PERSON: (eventId: number, personId: number) =>
    `${Modules.COMMON}/eventparticipant/${eventId}/registered/${personId}`,
  EVENT_PARTICIPATION_FOR_PERSON: (eventId: number, personId: number) =>
    `${Modules.COMMON}/eventparticipant/event/${eventId}/person/${personId}`,
  EVENT_DELETE: (id: number) => `${Modules.COMMON}/event/delete/${id}`,
  EVENT_ALL_RESPONSES: (id: number) =>
    `${Modules.COMMON}/event/responses/${id}/table`,
  CONTEST_EXTRA_FIELDS: (contestId: number) =>
    `${Modules.COMMON}/extrafield/contest/${contestId}`,
  GLOBALS: `${Modules.COMMON}/globals/all`,
  WF_YEAR: `${Modules.COMMON}/globals/WFYear`,
  INSTITUTION: `${Modules.COMMON}/institution`,
  INSTITUTION_IDNAMEDTO: `${Modules.COMMON}/institution/name`,
  INSTITUTION_UNIT: `${Modules.COMMON}/institutionunit`,
  INSTITUTION_UNITS_FOR_INST_ID: `${Modules.COMMON}/institutionunit/inst`,
  INSTITUTION_SUGGEST: `${Modules.COMMON}/institutionunit/suggest`,
  INSTITUTION_LOGO: (institutionId: number) =>
    `${Modules.COMMON}/logo/institution/${institutionId}`,
  SURVEY_FIELD_IMAGE: (fieldId: number) =>
    `${Modules.CONTEST}/survey/field/image/${fieldId}`,
  SUGGESTED_INSTITUTION: `${Modules.COMMON}/suggestedinstitution`,
  INSTITUTION_SUGGEST_BY_IUA_ID: (iuaId: number) =>
    `${Modules.COMMON}/suggestedinstitution/${iuaId}`,
  INSTITUTION_IUA_BY_ID: (iuaId: number) =>
    `${Modules.COMMON}/suggestedinstitution/${iuaId}/iua`,
  INSTITUTION_SUGGEST_BY_PERSON_ID: (personId: number) =>
    `${Modules.COMMON}/suggestedinstitution/person/${personId}`,

  // CONTEST

  STAFF_FUNDING: (contestParticipantId: number) =>
    `${Modules.CONTEST}/staffMember/funding/${contestParticipantId}`,
  HANDLE_CORRUPTED_CONTESTS: () => `${Modules.CONTEST}/handleCorruptedContests`,
  HANDLE_MISSING_COACHES: () => `${Modules.CONTEST}/handleMissingCoaches`,
  ROLLOVER: (contestId) => `${Modules.CONTEST}/rollover/contest/${contestId}`,
  ARCHIVE: (contestId) => `${Modules.CONTEST}/rollover/archive/${contestId}`,
  CONTEST: (contestId: number) => `${Modules.CONTEST}/${contestId}`,
  CONTEST_REVALIDATION_STATUS: (contestId: number) =>
    `${Modules.CONTEST}/registration/${contestId}/revalidate/status`,
  CONTEST_PARTICIPANT_GROUPS: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/participantGroup`,
  CONTEST_ANNOUNCEMENT: (contestId: number) =>
    `${Modules.CONTEST}/announcement/${contestId}`,
  CONTEST_SITES: (contestId: number) => `${Modules.CONTEST}/${contestId}/sites`,
  CONTEST_SUBCONTESTS: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/subcontests`,
  IS_WORLD_FINALS: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/worldFinalsCheck`,
  CONTEST_IDNAME_BY_ABBR_AND_YEAR: (abbr: string, year: number) =>
    `${Modules.CONTEST}/abbr/${abbr}/year/${year}`,
  HAS_CONTEST_ACCESS: (contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}`,
  IS_CONTEST_MANAGER: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}`,
  CAN_UPDATE_CONTEST: (contestId: number, personId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/updateContest`,
  CAN_CREATE_SITE: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/site/create`,
  CAN_UPDATE_SITE: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/site/update`,
  CAN_DELETE_SITE: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/site/delete`,
  CAN_CREATE_SUBCONTEST: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/subcontest/create`,
  CAN_DELETE_SUBCONTEST: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/subcontest/delete`,
  AGENT_BLACKLISTED_PERSONS: (agentId: number, contestId: number) =>
    `${Modules.CONTEST}/agent/${agentId}/contest/${contestId}/blacklist`,
  CONTEST_AGENTS: (contestId: number) =>
    `${Modules.CONTEST}/agent/contest/${contestId}`,
  CONTEST_AGENT: (contestId: number, agentName: string) =>
    `${Modules.CONTEST}/agent/contest/${contestId}/agent/${agentName}`,
  ACTIVE_AGENTS: (contestId: number) =>
    `${Modules.CONTEST}/agent/contest/${contestId}/agent/active`,
  DEFAULT_AGENT_DETAILS: (contestId: number) =>
    `${Modules.CONTEST}/agent/default/contest/${contestId}`,
  CONTEST_ATTACHMENT: (attachmentId: number) =>
    `${Modules.CONTEST}/attachment/${attachmentId}`,
  CONTEST_ATTACHMENTS: (contestId: number) =>
    `${Modules.CONTEST}/attachment/contest/${contestId}`,
  MANUAL_BADGE: (contestId: number, manualBadgeId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/badge/${manualBadgeId}`,
  STAFF_BADGE: (contestId: number, staffBadgeId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/badge/${staffBadgeId}`,
  TEAM_BADGE: (contestId: number, teamBadgeId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/badge/${teamBadgeId}`,
  TEAM_CERTIFICATE: (teamId: number) =>
    `${Modules.CONTEST}/certificate/team/${teamId}`,
  CERT_SETTINGS: (contestId: number) =>
    `${Modules.CONTEST}/certificatesettings/${contestId}`,
  INITIATE_ATTACHMENTS_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/attachments/contest/${contestId}/${type}`,
  ATTACHMENTS_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/attachments/contest/${contestId}/${type}/exists`,
  ATTACHMENTS_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/attachments/contest/${contestId}/${type}/status`,
  INITIATE_CDP_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cdp/contest/${contestId}/${type}`,
  CDP_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cdp/contest/${contestId}/${type}/exists`,
  CDP_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cdp/contest/${contestId}/${type}/status`,
  INITIATE_CLICS_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/clics/contest/${contestId}/${type}`,
  CLICS_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/clics/contest/${contestId}/${type}/exists`,
  CLICS_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/clics/contest/${contestId}/${type}/status`,
  INITIATE_CPF_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cpf/contest/${contestId}/${type}`,
  CPF_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cpf/contest/${contestId}/${type}/exists`,
  CPF_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/cpf/contest/${contestId}/${type}/status`,
  INITIATE_CONTEST_DATA_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/contest/${contestId}/${type}`,
  CONTEST_DATA_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/contest/${contestId}/${type}/exists`,
  CONTEST_DATA_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/contest/${contestId}/${type}/status`,
  CONTEST_EMAIL_EXPORT_FILE_CHECK: (contestId: number) =>
    `${Modules.CONTEST}/export/email/contest/${contestId}/exists`,
  CONTEST_EMAIL_EXPORT_PROGRESS_CHECK: (contestId: number) =>
    `${Modules.CONTEST}/export/email/contest/${contestId}/status`,
  INITIATE_PASSPORT_INFO_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/passportInfo/contest/${contestId}/${type}`,
  PASSPORT_INFO_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/passportInfo/contest/${contestId}/${type}/exists`,
  PASSPORT_INFO_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/passportInfo/contest/${contestId}/${type}/status`,
  INITIATE_HOST_INVITATION_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/hostinvitation/contest/${contestId}/${type}`,
  HOST_INVITATION_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/hostinvitation/contest/${contestId}/${type}/exists`,
  HOST_INVITATION_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/hostinvitation/contest/${contestId}/${type}/status`,
  INITIATE_PC2_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pc2/contest/${contestId}/${type}`,
  INITIATE_ALL_RELAXED_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/relaxReport/all/contest/${contestId}/${type}`,
  PC2_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pc2/contest/${contestId}/${type}/exists`,
  ALL_RELAXED_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/relaxReport/all/contest/${contestId}/${type}/exists`,
  PC2_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pc2/contest/${contestId}/${type}/status`,
  ALL_RELAXED_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/relaxReport/all/contest/${contestId}/${type}/status`,
  INITIATE_PLANNING_EXPORT: (contestId: number) =>
    `${Modules.CONTEST}/export/planning/contest/${contestId}`,
  PLANNING_EXPORT_FILE_CHECK: (contestId: number) =>
    `${Modules.CONTEST}/export/planning/contest/${contestId}/exists`,
  PLANNING_EXPORT_PROGRESS_CHECK: (contestId: number) =>
    `${Modules.CONTEST}/export/planning/contest/${contestId}/status`,
  INITIATE_TEAM_PDF_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pdf/contest/${contestId}/${type}`,
  TEAM_PDF_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pdf/contest/${contestId}/${type}/exists`,
  TEAM_PDF_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/pdf/contest/${contestId}/${type}/status`,
  INITIATE_WF_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/wf/contest/${contestId}/${type}`,
  WF_EXPORT_FILE_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/wf/contest/${contestId}/${type}/exists`,
  WF_EXPORT_PROGRESS_CHECK: (contestId: number, type: string) =>
    `${Modules.CONTEST}/export/wf/contest/${contestId}/${type}/status`,
  CONTEST_STATS: (contestId: number) =>
    `${Modules.CONTEST}/info/contest/${contestId}/stats`,
  PREVIOUS_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/info/contest/${contestId}/previous`,
  CONTEST_LINKS: (contestId: number) =>
    `${Modules.CONTEST}/link/contest/${contestId}`,
  PRELIMINARY_CONTEST: (contestId: number, preliminaryContestId: number) =>
    `${Modules.CONTEST}/preliminary/${preliminaryContestId}`,
  PRELIMINIARIES_FOR_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/preliminary/contest/${contestId}`,
  CONTEST_PUBLIC_INFO: (abbr: string) => `${Modules.CONTEST}/public/${abbr}`,
  CONTEST_PUBLIC_INFO_SITES: (abbr: string) =>
    `${Modules.CONTEST}/public/${abbr}/sites`,
  CONTEST_REGIONAL_RESULTS: (year: number) =>
    `${Modules.CONTEST}/public/regionalresults/${year}`,
  CONTEST_PUBLIC_INFO_SUBCONTESTS: (abbr: string) =>
    `${Modules.CONTEST}/public/subcontests/${abbr}`,
  CONTEST_PUBLIC_INFO_SUPERCONTESTS: (abbr: string) =>
    `${Modules.CONTEST}/public/supercontests/${abbr}`,
  CONTEST_REGISTRATION_INFO: (contestId: number) =>
    `${Modules.CONTEST}/registrationinfo/contest/${contestId}`,
  TEAM_CERT_REQUIRED: (teamId: number) =>
    `${Modules.CONTEST}/requireCertification/${teamId}`,
  ROSTER: (contestId: number) =>
    `${Modules.CONTEST}/roster/contest/${contestId}`,
  CONTEST_SETTINGS: (contestId: number) =>
    `${Modules.CONTEST}/settings/contest/${contestId}`,
  USER_ROLES: (contestId: string) =>
    `${Modules.CONTEST}/security/roles?contestId=${contestId}`,
  SITE: (siteId: number) => `${Modules.CONTEST}/site/${siteId}`,
  SITE_MANAGERS: (siteId: number) => `${Modules.CONTEST}/site/access/${siteId}`,
  SITES_FOR_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/site/contest/${contestId}/table`,
  SITE_BREADCRUMBS: (siteId: number) =>
    `${Modules.CONTEST}/site/${siteId}/breadcrumbs`,
  SURVEYS_FOR_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/survey/${contestId}/table`,
  SURVEY_RESPONSES_TABLE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/responses/${surveyId}/table`,
  SURVEY_CHANGE_ACCEPT_RESPONSES: (state: boolean, surveyid: number) =>
    `${
      Modules.CONTEST
    }/survey/${surveyid}/acceptsResponses/${state.toString()}`,
  SURVEY_DELETE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/delete/${surveyId}`,
  GET_SURVEY_BY_ID: (surveyId: number) =>
    `${Modules.CONTEST}/survey/${surveyId}`,
  SURVEY_FIELDS_FOR_SURVEY: (surveyId: number) =>
    `${Modules.CONTEST}/survey/field/${surveyId}/table`,
  SURVEY_FIELD_BY_ID: (fieldId: number) =>
    `${Modules.CONTEST}/survey/field/${fieldId}`,
  SURVEY_FIELD_DELETE: (fieldId: number) =>
    `${Modules.CONTEST}/survey/field/delete/${fieldId}`,
  GET_SURVEY_RESPONSE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/surveyResponse/${surveyId}`,
  DELETE_SURVEY_RESPONSE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/surveyResponse/delete/${surveyId}`,
  CONTEST_BREADCRUMBS: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/breadcrumbs`,
  CONSTEST_IDNAME_BY_SITE_ID: (siteId: number) =>
    `${Modules.CONTEST}/site/${siteId}/idname`,
  SITE_DTO: (siteId: number) => `${Modules.CONTEST}/site/${siteId}/sitedto`,
  SITE_SETTINGS: (siteId: number) =>
    `${Modules.CONTEST}/site/${siteId}/sitesettings`,
  SITE_ADDRESS: (siteId: number) =>
    `${Modules.CONTEST}/site/${siteId}/sitesettings/address`,
  SITE_IDNAME_BY_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/site/contest/${contestId}/idname`,
  SITE_DETAILS_DTO: (siteId: number) =>
    `${Modules.CONTEST}/site/details/${siteId}`,
  PROMOTION_SITES_FOR_TEAM: (teamId: number) =>
    `${Modules.CONTEST}/site/team/${teamId}/promote`,
  WF_SITES_FOR_TEAM: (teamId: number) =>
    `${Modules.CONTEST}/site/team/${teamId}/promote/WF`,
  MYSITES: `${Modules.CONTEST}/site/my`,
  GET_STAFF_TSHIRT_REPORT: (contestId: string) =>
    `${Modules.CONTEST}/staffmember/tshirt/contest/${contestId}/report`,
  GET_STAFF_TSHIRT: (staffMemberId: number) =>
    `${Modules.CONTEST}/staffmember/tshirt/staffmember/${staffMemberId}`,
  GET_STAFF_TSHIRT_SEARCH_BY_CONTEST_ID: (contestId: string) =>
    `${Modules.CONTEST}/staffmember/tshirt/contest/${contestId}/search`,
  CONTEST_SOCIAL_MEDIA_LINKS: (contestId: number) =>
    `${Modules.CONTEST}/social/contest/${contestId}`,
  SOCIAL_MEDIA_LINK: (socialMediaLinkId: number) =>
    `${Modules.CONTEST}/social/${socialMediaLinkId}`,
  CONTEST_SCHEDULES: (contestId) =>
    `${Modules.CONTEST}/public/${contestId}/schedules`,

  GET_STAFF_MEMBER_BY_ID: (smId: string) =>
    `${Modules.CONTEST}/staffmember/${smId}`,
  GET_STAFF_MEMBER_SEARCH_BY_CONTEST_ID: (contestId: string) =>
    `${Modules.CONTEST}/staffmember/contest/${contestId}/search`,
  GET_PUBLIC_STAFF_MEMBER_SEARCH_BY_CONTEST_ID: (contestId: string) =>
    `${Modules.CONTEST}/staffmember/contest/${contestId}/public/search`,
  GET_ORGANIZERS_BY_CONTEST_ID: (contestId: number) =>
    `${Modules.CONTEST}/public/organizers/${contestId}`,
  DOWNLOAD_INSTITUTION_PHOTO: (id: number) =>
    `${Modules.CONTEST}/public/institutionLogo/${id}/download`,
  DOWNLOAD_CONTEST_PUBLIC_PHOTO: (id: number) =>
    `${Modules.CONTEST}/public/contestPublicPhotos/${id}/download`,
  GET_CONTEST_PUBLIC_PHOTOS_BY_TYPE: (contestId: number, type) =>
    `${Modules.CONTEST}/publicPhoto/contest/${contestId}/type/${type}`,
  GET_CONTEST_PUBLIC_PHOTOS: (contestId: number) =>
    `${Modules.CONTEST}/public/contestPublicPhotos/${contestId}`,
  GET_LABELS_SEARCH_BY_CONTEST_ID: (contestId: number) =>
    `${Modules.CONTEST}/label/contest/${contestId}/search`,
  GET_LABELES_FOR_CONTEST: (contestId: number) =>
    `${Modules.CONTEST}/label/contest/${contestId}/labels`,
  GET_LABELES_FOR_STAFF_MEMBER: (staffmemberId: number) =>
    `${Modules.CONTEST}/label/staffmember/${staffmemberId}`,
  GET_LABELES_FOR_TEAM_MEMBER: (teammemberId: number) =>
    `${Modules.CONTEST}/label/teammember/${teammemberId}`,
  TRANSPORTATION_ENDPOINTS: (id: number) =>
    `${Modules.CONTEST}/transportation/endpoints/${id}`,
  FETCH_LABELS_I_LEAD: (id: number) =>
    `${Modules.CONTEST}/label/contest/${id}/labelsILead`,
  TRANSPORTATION_COMPANIES: (id: number) =>
    `${Modules.CONTEST}/transportation/companies/${id}`,
  PERSON_TRANSPORTATION_FOR_CONTEST: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/transportation/person/${personId}/contest/${contestId}`,
  PERSON_TRANSPORTATION_TEAM_BY_COACH: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/transportation/person/${personId}/contest/${contestId}/coach`,
  TRANSPORTATION_SETTINGS: (id: number) =>
    `${Modules.CONTEST}/transportation/settings/${id}`,
  VISA: (visaId: number) => `${Modules.PERSON}/visa/${visaId}`,

  TEAMS_ATTACHMENTS_PRESIGNEDURL: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/teamsAttachments/presignedurl`,

  // HELP
  HELP_TOPIC_BY_KEY: (key: string) => `${Modules.HELP}/topic/key/${key}`,
  HELP_TOPIC_ROOT: () => `${Modules.HELP}/topic/root`,
  HELP_TOPIC_SEARCH: () => `${Modules.HELP}/topic/search`,
  XWIKI_PAGE: (wiki: string, space: string, page: string) =>
    `${Modules.HELP}/cms/${wiki}/${space}/${page}`,

  //PROFILE
  PERSON_SOCIAL_INFO: (personId: number) =>
    `${Modules.PROFILE_COMMON}/personsocialinfo/person/${personId}`,
  PERSON_PROFILE_PICTURE: (personId: number) =>
    `${Modules.PROFILE_COMMON}/picture/person/${personId}`,
  PERSON_RESUME: (personId: number) =>
    `${Modules.PROFILE_COMMON}/resume/person/${personId}`,

  // PERSON
  PERSON_MAIN_INFO: (personId: number) => `${Modules.PERSON}/${personId}`,
  PERSON_EXTRA_FIELDS: (personId: number) =>
    `${Modules.PERSON}/${personId}/extrafield`,
  USERNAME_AVAILABLE: (email: string) => `${Modules.PERSON}/available/${email}`,
  PERSON_CONTACT_INFO: (personId: number) =>
    `${Modules.PERSON}/contactinfo/person/${personId}`,
  DASHBOARD_FOR_YEAR: (year: number) =>
    `${Modules.PERSON}/dashboard/year/${year}`,
  PERSON_DEGREE: (personId: number) =>
    `${Modules.PERSON}/degree/person/${personId}`,
  PERSON_FILES_APPOINTMENT_LETTERS: (personId: number) =>
    `${Modules.PERSON}/files/${personId}/appointmentletters`,
  ICPCId: (publicKey: string) => `${Modules.PERSON}/icpcid/public/${publicKey}`,
  ICPCIdKey: (personId: number) => `${Modules.PERSON}/icpcid/key/${personId}`,
  ICPCId_BY_PERSON: (personId: number) =>
    `${Modules.PERSON}/icpcid/person/${personId}`,
  PERSON_BASIC_INFO: `${Modules.PERSON}/info/basic`,
  PERSON_INFO: (personId: number) =>
    `${Modules.PERSON}/info/person/${personId}/latest`,
  PERSON_IS_OWNER: (personId: number) =>
    `${Modules.PERSON}/isowner/${personId}`,
  PERSON_NAME_BY_ID: (personId: number) => `${Modules.PERSON}/name/${personId}`,
  PERSON_PASSPORT_INFO: (personId: number) =>
    `${Modules.PERSON}/passportinfo/person/${personId}`,
  PERSON_PASSPORT_NOT_NEEDED: (personId: number) =>
    `${Modules.PERSON}/passportinfo/person/${personId}/notneeded`,
  PERSON_PASSPORT_PHOTO: (passportInfoId: number) =>
    `${Modules.PERSON}/passportphoto/${passportInfoId}`,
  PERSONAL_FILES: (personId: number, year: number) =>
    `${Modules.PERSON}/personalfile/${personId}/year/${year}`,
  PERSON_REG_DTO: (id: number) =>
    `${Modules.PERSON}/personregistrationdto/${id}`,
  PERSON_REFERENCES: (personId: number, year: number) =>
    `${Modules.PERSON}/references/${personId}/year/${year}`,
  PERSON_REGCOMPLETE_STATUS: (personId: number) =>
    `${Modules.PERSON}/registration/${personId}/reginfo`,
  PERSON_REGISTRATION_STATUS: (personId: number) =>
    `${Modules.PERSON}/registration/registrationStatus/${personId}`,
  PERSON_SUGGEST: `${Modules.PERSON}/suggest`,
  PERSON_VISA: (visaId: number, personId: number) =>
    `${Modules.PERSON}/visa/${visaId}/person/${personId}`,
  PERSON_VISA_NEEDED: (personId: number, wfContestId: number) =>
    `${Modules.PERSON}/visa/person/${personId}/contest/${wfContestId}/needed`,

  // TEAM
  TEAM: `${Modules.TEAM}`,
  TEAM_DTO: (teamId: number) => `${Modules.TEAM}/${teamId}`,
  TEAM_ACTION_DTO: (teamId: number) => `${Modules.TEAM}/${teamId}/action`,
  TEAM_EXTRA_FIELDS: (teamId: number) => `${Modules.TEAM}/${teamId}/extrafield`,
  TEAM_NAME_OFFENSIVE: (teamId: number) =>
    `${Modules.TEAM}/${teamId}/offensive`,
  TEAM_OTHER_SITES: (teamId: number) => `${Modules.TEAM}/${teamId}/othersites`,
  TEAM_RESTRICTIONS: (teamId: number) =>
    `${Modules.TEAM}/${teamId}/viewrestrictions`,
  HAS_TEAM_ACCESS: (personId: number, teamId: number) =>
    `${Modules.TEAM}/access/team/${teamId}/person/${personId}`,
  TEAM_CERT_INFO: (teamId: number) =>
    `${Modules.TEAM}/certification/${teamId}/info`,
  ELIGIBILITY: (teamId: number) => `${Modules.TEAM}/eligibility/team/${teamId}`,
  TEAM_FILES: (teamId: number) => `${Modules.TEAM}/file/team/${teamId}`,
  MISSING_ELIGIBLILTY_DOCUMENTS: (teamId: number) =>
    `${Modules.TEAM}/file/missingeligibilitydocuments/team/${teamId}`,
  MISSING_PROOF_OF_ID_DOCUMENTS: (teamId: number) =>
    `${Modules.TEAM}/file/missingProofOfIdDocuments/team/${teamId}`,
  TEAM_CERT_MEMBERS: (teamId: number) =>
    `${Modules.TEAM}/members/certification/${teamId}`,
  TEAM_MEMBERS: (teamId: number) => `${Modules.TEAM}/members/team/${teamId}`,
  TEAM_NAME_BY_ID: `${Modules.TEAM}/name`,
  TEAM_IS_SITE_REGISTRABLE: (siteId: number) =>
    `${Modules.TEAM}/site/${siteId}/registrable`,
  TEAM_CERT_FORM: (teamId: number) =>
    `${Modules.TEAM}/teaminfo/team/${teamId}/cert`,
  TEAM_CERT_CHECKIN: (teamId: number) =>
    `${Modules.TEAM}/teaminfo/team/${teamId}/checkintimes`,
  TEAM_CERT_NOTE: (teamId: number) =>
    `${Modules.TEAM}/teaminfo/team/${teamId}/note`,
  TEAMS_WF_ROSTERS: (year: number) => `${Modules.TEAM}/wf/${year}`,
  TEAMS_WF_PUBLISHED: (year: number) => `${Modules.TEAM}/wf/${year}/published`,

  //Hotel
  DASHBOARDDTOS_FOR_CONTEST_AND_USER: (contestId: number, userId: number) =>
    `${Modules.HOTEL_COMMON}/person/${userId}/reservations/${contestId}`,

  CONTEST_DATA_PACKAGE_PRESIGNED: (contestId: number) =>
    `${Modules.CONTEST}/cdp/contest/${contestId}/presigned`,
};

export const DataPOST = {
  // COMMON
  COUNTRY: `${Modules.COMMON}/country`,
  EVENT_PARTICIPATION_FOR_PERSON: (eventId: number, personId: number) =>
    `${Modules.COMMON}/eventparticipant/event/${eventId}/person/${personId}`,
  EVENT_CREATE: (contestId: number) =>
    `${Modules.COMMON}/event/create/${contestId}`,
  EVENT_UPDATE: (eventId: number) =>
    `${Modules.COMMON}/event/update/${eventId}`,
  CONTEST_EXTRA_FIELD: (contestId: number) =>
    `${Modules.COMMON}/extrafield/contest/${contestId}`,
  INSTITUTION: `${Modules.COMMON}/institution`,
  INSTITUTION_WITH_UNIT: `${Modules.COMMON}/institution/withunit`,
  /* For Institution suggested, however there are several similar names in this file already
       We need to be very careful about this
    */
  SUGGEST_INSTITUTION: `${Modules.COMMON}/suggestedinstitution/`,
  INSTITUTION_MERGE: (FromId: number, ToId: number) =>
    `${Modules.COMMON}/institutionunit/mergeInstitutions/${FromId}/${ToId}`,
  INSTITUTION_LOGO: (institutionId: number) =>
    `${Modules.COMMON}/logo/institution/${institutionId}`,

  // CONTEST
  CONTEST: (contestId: number) => `${Modules.CONTEST}/${contestId}`,
  CONTEST_REVALIDATE: (contestId: number) =>
    `${Modules.CONTEST}/registration/${contestId}/revalidate`,

  CONTEST_MEDIA_UPLOAD: (contestId: number) =>
    `${Modules.CONTEST}/media/contest/${contestId}/upload`,
  SITE_IMAGE_UPLOAD: (contestId: number, type) =>
    `${Modules.CONTEST}/publicPhoto/contest/${contestId}/type/${type}`,

  CONTEST_ATTACHMENT_UPLOAD: (contestId: number) =>
    `${Modules.CONTEST}/attachment/contest/${contestId}`,
  CONTEST_ANNOUNCEMENT: (contestId: number) =>
    `${Modules.CONTEST}/announcement/${contestId}`,
  SUBCONTEST_CREATE: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/subcontest/create`,
  SITE_MANAGER_ADD: (siteId: number, personId: number) =>
    `${Modules.CONTEST}/site/access/${siteId}/person/${personId}`,
  AGENT_BLACKLIST_PERSON: (agentId: number, contestId: number) =>
    `${Modules.CONTEST}/agent/${agentId}/contest/${contestId}/blacklist/add`,
  AGENT_REMOVE_BLACKLISTED_PERSON: (
    agentId: number,
    contestId: number,
    personId: number
  ) =>
    `${Modules.CONTEST}/agent/${agentId}/contest/${contestId}/blacklist/remove/person/${personId}`,
  AGENT_EMAIL: (agentId: number, contestId: number) =>
    `${Modules.CONTEST}/agent/${agentId}/contest/${contestId}/email`,
  AGENT_UPDATE: (contestId: number) =>
    `${Modules.CONTEST}/agent/contest/${contestId}`,
  CREATE_MANUAL_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}`,
  MANUAL_BADGE: (contestId: number, manualBadgeId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/badge/${manualBadgeId}`,
  GENERATE_MISSING_MANUAL_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/missing`,
  DELETE_SELECTED_MANUAL_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}/selected`,
  STAFF_BADGE: (contestId: number, staffBadgeId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/badge/${staffBadgeId}`,
  GENERATE_MISSING_STAFF_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/missing`,
  DELETE_SELECTED_STAFF_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}/selected`,
  TEAM_BADGE: (contestId: number, teamBadgeId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/badge/${teamBadgeId}`,
  GENERATE_MISSING_TEAM_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/missing`,
  DELETE_SELECTED_TEAM_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}/selected`,
  INITIATE_CONTEST_EMAIL_EXPORT: (contestId: number) =>
    `${Modules.CONTEST}/export/email/contest/${contestId}`,
  CONTEST_LINKS: (contestId: number) =>
    `${Modules.CONTEST}/link/contest/${contestId}`,
  PRELIMINARY_CONTEST_CREATE: (contestId: number) =>
    `${Modules.CONTEST}/preliminary/contest/${contestId}`,
  CONTEST_REGISTRATION_INFO: (contestId: number) =>
    `${Modules.CONTEST}/registrationinfo/contest/${contestId}`,
  CONTEST_SETTINGS: (contestId: number) =>
    `${Modules.CONTEST}/settings/contest/${contestId}`,
  CONTEST_READ_ONLY: (contestId: number, readOnly: boolean) =>
    `${Modules.CONTEST}/settings/contest/${contestId}/readOnly/${readOnly}`,
  CONTEST_REQUIRE_ELIGIBILITY_VALIDATION: (
    contestId: number,
    requireEligibilityValidation: boolean
  ) =>
    `${Modules.CONTEST}/settings/contest/${contestId}/requireEligibilityValidation/${requireEligibilityValidation}`,
  CONTEST_PUBLISH_CONTEST_MATERIALS: (
    contestId: number,
    publishContestMaterials: boolean
  ) =>
    `${Modules.CONTEST}/settings/contest/${contestId}/publishContestMaterials/${publishContestMaterials}`,
  CONTEST_FORCE_ENROLLMENT_LETTERS: (
    contestId: number,
    forceEnrollemntLetters: boolean
  ) =>
    `${Modules.CONTEST}/settings/contest/${contestId}/forceEnrollemntLetters/${forceEnrollemntLetters}`,
  UPLOAD_STANDINGS: (contestKey: string) =>
    `${Modules.CONTEST}/standings/contest/${contestKey}`,
  SITE_ADDRESS: (contestId: number, siteId: number) =>
    `${Modules.CONTEST}/site/${siteId}/sitesettings/address/contest/${contestId}`,
  SITE: (contestId: number) =>
    `${
      Modules.CONTEST // note: we need to validate @SiteManager(context = Contest.class) on the server, thus sending contestId
    }/site/contest/${contestId}`,
  SITE_CREATE: (contestId: number) =>
    `${Modules.CONTEST}/site/create/${contestId}`,
  SITE_SETTINGS: (contestId: number) =>
    `${Modules.CONTEST}/site/sitesettings/contest/${contestId}`,
  SURVEY_CREATE: (contestId: number) =>
    `${Modules.CONTEST}/survey/create/${contestId}`,
  SURVEY_UPDATE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/update/${surveyId}`,
  SURVEY_FIELD_CREATE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/field/create/${surveyId}`,
  SURVEY_FIELD_UPDATE: (surveyFieldId: number) =>
    `${Modules.CONTEST}/survey/field/update/${surveyFieldId}`,
  SEND_SURVEY_RESPONSE: (surveyId: number) =>
    `${Modules.CONTEST}/survey/surveyResponse/create/${surveyId}`,
  SOCIAL_MEDIA_LINK: (contestId: number) =>
    `${Modules.CONTEST}/social/contest/${contestId}`,
  SOCIAL_MEDIA_UPDATE_LINK: (socialMediaLinkId: number, contestId: number) =>
    `${Modules.CONTEST}/social/contest/${socialMediaLinkId}/update/${contestId}`,
  IMPORT_FILE_BY_CONTEST_ID: (contestId: string) =>
    `${Modules.CONTEST}/staffmember/contest/${contestId}/import`,
  IMPORT_LABELS_FILE_BY_CONTEST_ID: (contestId: number) =>
    `${Modules.CONTEST}/label/contest/${contestId}/import`,
  LABEL_CREATE: (contestId: number) =>
    `${Modules.CONTEST}/label/contest/${contestId}`,
  STAFF_MEMBER_CREATE: (siteId: number) =>
    `${Modules.CONTEST}/staffmember/site/${siteId}`,
  STAFF_MEMBER_TSHIRT_ADD: (staffMemberId: number) =>
    `${Modules.CONTEST}/staffmember/tshirt/staffmember/${staffMemberId}`,
  CERTIFY_STANDINGS: (standingsId: number) =>
    `${Modules.CONTEST}/standings/${standingsId}/certify`,
  PUBLISH_STANDINGS: (standingsId: number) =>
    `${Modules.CONTEST}/standings/${standingsId}/apply`,
  // UNPUBLISH_STANDINGS: (standingsId: number) =>
  //   `${Modules.CONTEST}/standings/${standingsId}/apply/unpublish`,
  COPY_STANDINGS: (contestId: number, standingsId: number) =>
    `${Modules.CONTEST}/standings/contest/${contestId}/standings/${standingsId}/copy`,
  TEAM_ORDER_FILE_UPLOAD: (contestId: number) =>
    `${Modules.CONTEST}/teamorder/contest/${contestId}`,
  TEAM_ORDER_GENERATION: (contestId: number) =>
    `${Modules.CONTEST}/teamorder/generate/contest/${contestId}`,
  TRANSPORTATION_ENDPOINTS: (id: number) =>
    `${Modules.CONTEST}/transportation/contest/${id}/endpoints`,
  TRANSPORTATION_COMPANIES: (id: number) =>
    `${Modules.CONTEST}/transportation/contest/${id}/companies`,
  PERSON_TRANSPORTATION_FOR_CONTEST: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/transportation/person/${personId}/contest/${contestId}`,
  PERSON_TRANSPORTATION_TEAM_SYNC: (personId: number, contestId: number) =>
    `${Modules.CONTEST}/transportation/person/${personId}/contest/${contestId}/sync`,
  TRANSPORTATION_SETTINGS: (id: number) =>
    `${Modules.CONTEST}/transportation/contest/${id}/settings`,
  STAFF_FUNDING_UPDATE: (contestParticipantId: number) =>
    `${Modules.CONTEST}/staffMember/funding/update/${contestParticipantId}`,
  CONTEST_DATA_PACKAGE: (contestId: number) =>
    `${Modules.CONTEST}/cdp/contest/${contestId}`,
  TEAMS_ATTACHMENTS: (contestId: number) =>
    `${Modules.CONTEST}/${contestId}/teamsAttachments`,
  CONTEST_DATA_PACKAGE_EXPORT: (contestId: number, type: string) =>
    `${Modules.CONTEST}/cdp/contest/${contestId}/${type}/download`,
  RULES_HISTORY: (contestId: number) =>
    `${Modules.CONTEST}/rule/contest/${contestId}`,
  CONTEST_ACCESS_MANAGER: (contestId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager`,

  CONTEST_SCHEDULES: (contestId) => `${Modules.CONTEST}/${contestId}/schedules`,

  // CONTEST_ACCESS: (contestId: number) =>
  //   `${Modules.CONTEST}/access/contest/${contestId}/manager`,
  TOGGLE_ACCESS: (
    contestId: number,
    personId: number,
    permission: string,
    recursive: number
  ) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}/permission/${permission}/recursive/${recursive}`,

  // HELP

  HELP_TOPIC_ADD: () => `${Modules.HELP}/topic/new`,
  HELP_POST_ISSUE: (topicId: number) =>
    `${Modules.HELP}/issue/topic/${topicId}`,

  // HOTEL
  HOTEL_DTOS_FOR_CONTESTS: (personId: number) =>
    `${Modules.HOTEL_COMMON}/hotels/${personId}/getHotelInfo`,

  // PROFILE
  PERSON_SOCIAL_INFO: (personId: number) =>
    `${Modules.PROFILE_COMMON}/personsocialinfo/person/${personId}`,
  PERSON_PROFILE_PICTURE: (personId: number) =>
    `${Modules.PROFILE_COMMON}/picture/person/${personId}`,
  PERSON_RESUME: (personId: number) =>
    `${Modules.PROFILE_COMMON}/resume/person/${personId}`,

  // PERSON
  PERSON_MAIN_INFO: (personId: number) => `${Modules.PERSON}/${personId}`,
  PERSON_INFO: (personId: number) =>
    `${Modules.PERSON}/info/person/${personId}/latest`,
  PERSON_EXTRA_FIELDS: (personId: number) =>
    `${Modules.PERSON}/${personId}/extrafield`,
  PERSON_CONTACT_INFO: (personId: number) =>
    `${Modules.PERSON}/contactinfo/person/${personId}`,
  PERSON_DEGREE: (personId: number) =>
    `${Modules.PERSON}/degree/person/${personId}`,
  PERSON_APPOINTMENT_LETTER_XLS: () =>
    `${Modules.PERSON}/files/appointmentletters/import`,
  ICPCId: (personId: number) => `${Modules.PERSON}/icpcid/person/${personId}`,
  PERSON_ICPCID_CREATE: (personId: number) =>
    `${Modules.PERSON}/icpcid/person/${personId}/create`,
  PERSON_PASSPORT_INFO: `${Modules.PERSON}/passportinfo`,
  PERSON_PASSPORT_PHOTO: (passportInfoId: number) =>
    `${Modules.PERSON}/passportphoto/${passportInfoId}`,
  PERSONAL_FILES: (personId: number, year: number) =>
    `${Modules.PERSON}/personalfile/${personId}/import/${year}`,
  ACCOUNT_MERGE: (personFromId: number, personToId: number) =>
    `${Modules.PERSON}/registration/mergeAccounts/${personFromId}/${personToId}`,
  PERSON_REGISTER: `${Modules.PERSON}/registration/`,
  REGISTRATION: (username: string) =>
    `${Modules.PERSON}/registration/finish/person/${username}`,
  PERSON_REGISTER_VIA_SUGGEST: () =>
    `${Modules.PERSON}/registration/registerviasuggest`,
  PERSON_USERNAME_CHANGE: (newUsername: string) =>
    `${Modules.PERSON}/registration/usernamechange/${newUsername}`,
  PERSON_VISA: (personId: number) =>
    `${Modules.PERSON}/visa/person/${personId}`,

  // TEAM
  TEAM_UPDATE: (teamId: number) => `${Modules.TEAM}/${teamId}`,
  TEAM_ACCEPT: (teamId: number) => `${Modules.TEAM}/${teamId}/accept`,
  TEAM_ACTION_DTO: (teamId: number) => `${Modules.TEAM}/${teamId}/action`,
  TEAM_CANCEL: (teamId: number) => `${Modules.TEAM}/${teamId}/cancel`,
  TEAM_EXTRA_FIELDS: (teamId: number) => `${Modules.TEAM}/${teamId}/extrafield`,
  TEAM_NAME_INOFFENSIVE: (teamId: number) =>
    `${Modules.TEAM}/${teamId}/inoffensive`,
  TEAM_SET_PENDING: (teamId: number) => `${Modules.TEAM}/${teamId}/waitlist`,
  ELIGIBILITY: (teamId: number) => `${Modules.TEAM}/eligibility/team/${teamId}`,
  ELIGIBILITY_REVALIDATE: (teamId: number) =>
    `${Modules.TEAM}/eligibility/team/${teamId}/revalidate`,
  TEAM_PROMOTE: (teamId: number, siteId: number) =>
    `${Modules.TEAM}/${teamId}/promote/${siteId}`,
  TEAM_FILE: (teamId: number) => `${Modules.TEAM}/file/${teamId}`,
  TEAMMEMBER_UPDATE: (teamMemberId) =>
    `${Modules.TEAM}/members/${teamMemberId}`,
  TEAM_MEMBERS_ADD: (teamId: number) =>
    `${Modules.TEAM}/members/team/${teamId}/add`,
  TEAM_REGISTER: `${Modules.TEAM}/register`,
  TEAM_REGISTER_BULK: `${Modules.TEAM}/register/bulk`,
  TEAM_REGISTER_CUSTOM_COACH: `${Modules.TEAM}/register/customcoach`,
  TEAM_VALIDATE_NAME_BULK: (siteId: number) =>
    `${Modules.TEAM}/validate/site/${siteId}`,
};

export const DataPUT = {
  //Tshirt Management
  TSHIRT_MANAGEMENT_UPDATE: (siteId: number) =>
    `${Modules.CONTEST}/staffmember/site/${siteId}`,

  // CONTEST
  CERT_SETTINGS_UPDATE: (contestId: number) =>
    `${Modules.CONTEST}/certificatesettings/${contestId}`,
  CUSTOM_BACKGROUND_PREVIEW: () =>
    `${Modules.CONTEST}/certificatesettings/certificatepreview`,
  LABEL_UPDATE: (contestId: number) =>
    `${Modules.CONTEST}/label/contest/${contestId}`,
  STAFF_MEMBER_UPDATE: (siteId: number) =>
    `${Modules.CONTEST}/staffmember/site/${siteId}`,
  ADD_LABEL_TO_STAFF: (staffMemberId: number, labelId: number) =>
    `${Modules.CONTEST}/label/${labelId}/add/staffmember/${staffMemberId}`,
  ADD_LABEL_TO_TEAM_MEMBER: (teammemberId: number, labelId: number) =>
    `${Modules.CONTEST}/label/${labelId}/add/teammember/${teammemberId}`,
  REMOVE_LABEL_FROM_STAFF: (staffMemberId: number, labelId: number) =>
    `${Modules.CONTEST}/label/${labelId}/remove/staffmember/${staffMemberId}`,
  REMOVE_LABEL_FROM_Team_MEMBER: (teammemberId: number, labelId: number) =>
    `${Modules.CONTEST}/label/${labelId}/remove/teammember/${teammemberId}`,
  SITE: (siteId: number, contestId: number) =>
    `${Modules.CONTEST}/site/${siteId}/move/${contestId}`,

  // HELP
  HELP_TOPIC_UPDATE: () => `${Modules.HELP}/topic/`,
  XWIKI_UPDATE: () => `${Modules.HELP}/cms/update`,

  // PERSON
  PERSON_SWITCH_ENABLED: (personId: number) =>
    `${Modules.PERSON}/${personId}/switchenabled`,
  PERSON_SWITCH_REGCOMPLETE: (personId: number) =>
    `${Modules.PERSON}/${personId}/switchregcomplete`,
  REFERENCES_ALLOW_ACCESS: (personId: number, masterSlaveId: number) =>
    `${Modules.PERSON}/access/person/${personId}/record/${masterSlaveId}/allow`,
  REFERENCES_DENY_ACCESS: (personId: number, masterSlaveId: number) =>
    `${Modules.PERSON}/access/person/${personId}/record/${masterSlaveId}/deny`,
  APPOINTMENT_CHANGE_STATUS: (personId: number) =>
    `${Modules.PERSON}/files/${personId}/appointmentletters`,
  PERSON_PASSPORT_NOT_NEEDED: (personId: number) =>
    `${Modules.PERSON}/passportinfo/notneeded/person/${personId}`,
  PERSON_VISA_NEEDED: (personId: number) =>
    `${Modules.PERSON}/visa/needed/person/${personId}`,

  // TEAM
  TEAM_MOVE: (teamId: number) => `${Modules.TEAM}/move/${teamId}`,
  TEAM_BULK_UPDATE: (siteId: number) => `${Modules.TEAM}/bulkupdate/${siteId}`,
  TEAM_BULK_UPDATE_CONTEST: (contestId: number) =>
    `${Modules.TEAM}/bulkupdate/contest/${contestId}`,
  TEAM_UPDATE_COACH: (teamId: number) =>
    `${Modules.TEAM}/members/team/${teamId}/coach`,
  TEAM_CERT_NOTE: (teamId: number) =>
    `${Modules.TEAM}/teaminfo/team/${teamId}/note`,
  TEAM_CERT_FORM: (teamId: number) =>
    `${Modules.TEAM}/teaminfo/team/${teamId}/cert`,
  TEAM_CERTIFY: (teamId: number) => `${Modules.TEAM}/${teamId}/certify`,
  TEAM_UNCERTIFY: (teamId: number) => `${Modules.TEAM}/${teamId}/uncertify`,
};

export const DataDELETE = {
  // COMMON
  EXTRA_FIELD: (extraFieldId: number) =>
    `${Modules.COMMON}/extrafield/${extraFieldId}`,

  // CONTEST
  CONTEST_ACCESS: (contestId: number, personId: number) =>
    `${Modules.CONTEST}/access/contest/${contestId}/manager/${personId}`,
  DELETE_STANDINGS: (standingsId: number) =>
    `${Modules.CONTEST}/standings/${standingsId}`,
  RESPONSE_DELETE: (transportationId: number) =>
    `${Modules.CONTEST}/transportation/${transportationId}`,
  ENDPOINTS_DELETE: (endpointId: number) =>
    `${Modules.CONTEST}/transportation/endpoint/${endpointId}`,
  TRANSPORTATION_COMPANY_DELETE: (companyId: number) =>
    `${Modules.CONTEST}/transportation/company/${companyId}`,
  CONTEST: (contestId: number) => `${Modules.CONTEST}/${contestId}`,
  SITE_MANAGER_DELETE: (siteId: number, personId: number) =>
    `${Modules.CONTEST}/site/access/${siteId}/person/${personId}`,
  CONTEST_ATTACHMENT: (attachmentId: number) =>
    `${Modules.CONTEST}/attachment/${attachmentId}`,
  ALL_MANUAL_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/manual/contest/${contestId}`,
  ALL_STAFF_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/staff/contest/${contestId}`,
  ALL_TEAM_BADGE: (contestId: number) =>
    `${Modules.CONTEST}/badge/team/contest/${contestId}`,
  MY_CERTIFICATE: (certificateDetailsId: number) =>
    `${Modules.CONTEST}/certificate/delete/${certificateDetailsId}`,
  PRELIMINARY_CONTEST: (preliminaryContestId: number) =>
    `${Modules.CONTEST}/preliminarycontest/${preliminaryContestId}`,
  SOCIAL_MEDIA_LINK: (socialMediaLinkId: number) =>
    `${Modules.CONTEST}/social/${socialMediaLinkId}`,
  LABEL_DELETE: (labelId: number) => `${Modules.CONTEST}/label/${labelId}`,
  STAFF_MEMBER_DELETE: (staffMemberId: number) =>
    `${Modules.CONTEST}/staffmember/${staffMemberId}`,
  STAFF_TSHIRT_DELETE: (staffMemberId: number, type: string) =>
    `${Modules.CONTEST}/staffmember/tshirt/staffmember/${staffMemberId}/type/${type}`,
  SITE: (siteId: number) => `${Modules.CONTEST}/site/${siteId}`,

  CONTEST_SCHEDULE: (contestId, scheduleId) =>
    `/contest/${contestId}/schedules/${scheduleId}`,

  DELETE_CONTEST_PUBLIC_PHOTO: (id) => `${Modules.CONTEST}/publicPhoto/${id}`,

  // HELP
  XWIKI_PAGE_CACHE: (wiki: string, space: string, page: string) =>
    `${Modules.HELP}/cms/${wiki}/${space}/${page}`,

  // PERSON
  APPOINTMENT: (alId: number) =>
    `${Modules.PERSON}/files/appointmentletters/${alId}`,
  PERSON_RESUME: (personId: number) =>
    `${Modules.PROFILE_COMMON}/resume/person/${personId}`,

  // TEAM
  TEAM: (teamId: number) => `${Modules.TEAM}/${teamId}`,
  TEAM_BULK_DELETE: `${Modules.TEAM}/bulkdelete`,
  TEAM_FILE: (teamFileId: number) => `${Modules.TEAM}/file/${teamFileId}`,
  TEAM_MEMBER: (memberId: number) => `${Modules.TEAM}/members/${memberId}`,
};

export const FormStructure = {
  LOGIN: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.LoginDto`,
  LOGIN_PASSWORD_FORGOT: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.SendPasswordResetEmailDto`,
  LOGIN_PASSWORD_CHANGE: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.ChangePasswordDto`,
  LOGIN_PASSWORD_FORCE_CHANGE: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.ForceChangePasswordDto`,
  LOGIN_PASSWORD_RESET: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.ResetPasswordDto`,
  LOGIN_CONFIRM_REGISTER: `${Modules.ASPECT_FACES}/global.icpc.base.model.dto.auth.ConfirmUserDto`,
  REGISTER: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.dtos.registration.RegisterDto`,
  ICPCId: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.icpcid.ICPCId`,
  PERSON_MAIN_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.Person->personInfo`,
  PERSON_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.PersonInfoAbstract`,
  PERSON_CONTACT_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.ContactInfo->shippingAddress`,
  PERSON_DEGREE: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.Degree`,
  PERSON_SOCIAL_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.PersonSocialInfo`,
  PERSON_PASSPORT_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.businessobjects.PassportInfo`,
  PERSON_PASSWORD_CHANGE: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.dtos.PersonPasswordChangeDto`,
  PERSON_EMAIL_CHANGE: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.dtos.PersonEmailChangeDto`,
  PERSON_REGISTRATION_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.dtos.registration.PersonRegistrationDto`,
  COUNTRY: `${Modules.ASPECT_FACES}/global.icpc.base.model.businessobjects.location.Country`,

  STAFF_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.staff.StaffDto`,
  TSHIRT_ADD_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.participant.AddTShirtDto`,

  INSTITUTION: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.businessobjects.institution.Institution`,
  INSTITUTION_UNIT: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.businessobjects.institution.InstitutionUnit->mailingAddress,socialInfo`,
  SUGGESTED_INSTITUTION: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.businessobjects.institution.SuggestedInstitution->mailingAddress`,
  EXTRA_FIELD_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.dtos.extra.ExtraFieldCreateDto`,
  ELIGIBILITY: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.businessobjects.Eligibility`,
  TEAM: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.businessobjects.Team`,
  TEAM_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.businessobjects.Team->teamInfo`,
  TEAM_ACTION_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.dtos.TeamActionDto`,

  TEAM_MEMBER: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.businessobjects.TeamMember`,

  CREATE_INSTITUTION_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.dtos.institution.InstitutionCreateDto`,
  REGISTRATION: `${Modules.ASPECT_FACES}/global.icpc.base.model.person.dtos.registration.UserRegistrationPart2Dto`,
  TEAM_MEMBER_REGISTRATION_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.dtos.TeamMemberRegistrationDto->person`,
  TEAM_MEMBER_REGISTRATION_DTO_LAZY: `${Modules.ASPECT_FACES}/global.icpc.base.model.team.dtos.TeamMemberRegistrationDto`,
  VISA: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.transportation.Visa`,
  SITE: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.site.Site`,
  SITE_SETTINGS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.site.SiteSettings`,
  SURVEY: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.survey.Survey`,
  SURVEY_FIELD: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.survey.SurveyField`,

  ADDRESS: `${Modules.ASPECT_FACES}/global.icpc.base.model.businessobjects.location.Address`,
  TRANSPORTATION: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.transportation.Transportation`,
  AGENT: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.tasks.Agent`,
  AGENT_BLACKLIST_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.agent.AgentBlackListDto`,
  EVENT: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.businessobjects.event.Event`,
  EVENT_PARTICIPANT: `${Modules.ASPECT_FACES}/global.icpc.base.model.common.businessobjects.event.EventParticipant`,
  XWIKI: `${Modules.ASPECT_FACES}/global.icpc.model.businessobjects.xwiki.XWikiCache`,
  CERTIFICATE_SETTINGS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.print.certificate.CertificateSettings`,
  CONTEST: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.Contest`,
  CONTEST_SETTINGS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.ContestSettings`,
  CONTEST_REGISTRATION_INFO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.ContestRegistrationInfo`,
  CONTEST_LINK: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.ContestLink`,
  PRELIMINARY_CONTEST: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.PreliminaryContest`,
  SITE_WITH_SETTINGS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.site.Site->siteSettings`,
  SOCIAL_MEDIA_LINK: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.SocialMediaLink`,
  CONTEST_ACCESS_MANAGER: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.access.AddContestManagerDto`,
  MANUAL_BADGE_CREATE_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.print.badge.AddManualBadgeDto`,
  BADGE_UPDATE_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.print.badge.BadgeDto`,
  TEAM_BADGE_UPDATE_DTO: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.dtos.print.badge.TeamBadgeDto`,
  TRANSPORTATION_ENDPOINTS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.transportation.TransportationEndpoint`,
  TRANSPORTATION_COMPANIES: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.transportation.TransportationCompany`,
  TRANSPORTATION_SETTINGS: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.transportation.TransportationSettings`,
  STAFF_FUNDING_UPDATE: `${Modules.ASPECT_FACES}/global.icpc.base.model.contest.businessobjects.staff.FundingInformation`,
};
